$highlight_background   : #1d1f21;
$highlight_current_line : #282a2e;
$highlight_selection    : #373b41;
$highlight_selection_bg : #afe7e7;
$highlight_foreground   : #c5c8c6;
$highlight_comment      : #969896;
$highlight_red          : #cc6666;
$highlight_orange       : #de935f;
$highlight_yellow       : #f0c674;
$highlight_green        : #b5bd68;
$highlight_aqua         : #8abeb7;
$highlight_blue         : #81a2be;
$highlight_purple       : #b294bb;
$highlight_gutter_color : #888f96;
$highlight_gutter_bg    : #000000;
$highlight_addition     : #008000;
$highlight_deletion     : #800000;

@import '../style.scss';